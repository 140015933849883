<template>
    <modal ref="modalCertificado" :titulo="titulo" no-aceptar no-cancelar tamano="modal-lg">
        <div class="row mx-0 justify-center">
            <div class="col-auto">
                <img :src="img" class="obj-cover" style="max-width:395px;max-height:100%;" /> 
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props:{
        titulo: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: '/img/no-imagen/default.jpg'
        },
        
    },
    data(){
        return{

        }
    },
    methods: {
        toggle(){
            this.$refs.modalCertificado.toggle();
        }
    }
}
</script>